body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-container {
  padding: 8px 0;
  height: 55px;
}

.pointer {
  cursor: pointer;
}

.login-form {
  max-width: 350px;
  margin: auto;
  box-shadow: 0 2px 25px rgb(0 0 0 / 20%);
  padding: 10px;
  background-color: white;
  margin-top: 100px;
}

.leaflet-container {
  width: 100%;
  height: calc(100dvh - 55px);
}

.leaflet-control-zoom {
  display: none!important;
}

.leaflet-control-attribution {
  display: none !important;
}

.leaflet-tooltip {
  position: absolute;
  padding: 0px !important; 
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0,0,0,0.4);
}

.leaflet-popup-content-wrapper {
  padding: 1px !important;
  border-radius: 2px !important;
}

.leaflet-control-layers label {
  font-weight: normal;
  margin-bottom: 0px;
}

.leaflet-popup-content {
  margin: 1px !important;
}

.leaflet-popup-close-button {
  display: none;
}

.popup-container {
  border: 1px solid black;
  padding: 2px;
  border-radius: 3px;
}

.map-container {
  flex-grow: 1;
  height: calc(100dvh - 55px);
  margin-right: 0px;
}

.status-counter {
  z-index: 15000;
  background-color: red;
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: 0;
}

.counters {
  margin-bottom: 25px !important;
  display: flex;
}

.circle {
  margin:3px;
  width: 40px;
  height: 40px;
  border-radius: 250px;
  font-size: 17px;
  font-weight: bold;
  color: black;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.notMovingCounter {
  background:green;
  margin-left:-3px;
  color:white;
  border: 1px solid #043002;
}

.movingCounter {
  background:blue;
  color:white;
  border: 1px solid #050527;
}

.overSpeedCounter {
  background:red;
  color:white;
  border: 1px solid #6e0000;
}

.reladiCounter{
  background: #F08000;
  color: white;
  border: 1px solid #944a17;
}

.workCounter {
  background: #ffef00;
  color: white;
  border: 1px solid #d0ab00;
}

.outOfOrderCounter {
  background: grey;
  color: white;
  border: 1px solid #5a5151;
}

.text-shadow {
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.supervision {
  width: 50px;
  height: 50px;
}

.eco-bar {
  height: 4px;
  float: left;
}

.eco-arrow {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}

.stop-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.filter {
  width: 380px;
  background-color: white;
}

@media (max-width: 576px) {
  .filter-arrow {
    display: none;
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 577px) {
  .filter-arrow {
    display: block;
    width: 30px;
    height: 30px;
  }
}

.fleet-filter {
  display: flex;
  height: 45px;
  background-color: #80808026
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 380px;
  position: absolute;
  top: 0;
  left: 20px;
}

.no-headers-grid .k-grid-header {
  display: none;
}

.k-table-td {
  padding: 0px !important;
}

.sign {
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-flex;
}

.leafNodeCount {
  margin-top: 2px;
  font-size: 11px !important;
}

.icon {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.h-35 {
  height: 35px;
}

.m-5px{
  margin: 5px;
}

.ml-3px {
  margin-left: 3px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25%;
}

.ml-40 {
  margin-left: 40%;
}

.mt-3px {
  margin-top: 3px;
}

.mt-7 {
  margin-top: -7px;
}

.mt-5px {
  margin-top: -5px;
}

.mt-130px {
  margin-top: 130px;
}

.mr-5px {
  margin-right: 5px;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.w-15 {
  width: 15px;
}

.w-32 {
  width: 32px;
}

.w-70px {
  width: 70px;
}

.w-100px {
  width: 100px
}

.w-290px {
  width: 290px;
}

.border-top-1 {
  border-top: 1px solid black;
}

.padding-2 {
  padding: 2px;
}

.station-filter-container {
  height: 130px;
  background-color: #80808026;
  position: absolute;
  width: 380px;
}

.station-filter {
  margin-left: 5px;
  margin-right: 5px;
}

.table td, .table th {
  padding: 0px !important;
}

.station {
  overflow-y: auto;
  overflow-x: hidden;
  width: 380px;
  margin-top: -16px;
  height: 100vh -200px;
  position: absolute;
  bottom: 0;
  top: 250px;
}

.stop-icon-container {
  width: 80px !important;
  text-align: center;
}

.stop-info {
  margin-left: -25px;
  width: 280px;
}

.stop-distance {
  margin-left: 90px;
  width: 70px;
  text-align: center;
}

.station-route-button {
  margin-top: -10px;
  margin-bottom: 3px;
  width: 40px;
  height: 30px;
}

.station-arrow {
  width: 20px;
  height: 20px;
  margin-left: -3px;
  margin-top: -10px;
}

.bgc-grey {
  background-color: #7370705c !important;
}

.border:hover {
  background-color: #f0f0f0;
}

.divIcon {
  position: relative;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: 700;
  font-size: 10px;
  text-align: center;
  font-family: "Segoe UI",sans-serif;
}

.starIcon-centered {
  top: -14px;
  left: 13px;
}

.circleIcon-centered {
  top: -9px;
  left: 8px;
}

.leaflet-div-icon {
  background: none !important;
  border: none !important;
}

.stationPopupContainer {
  border: 1px solid black;
  padding: 2px;
  border-radius: 3px;
  font-size: 13px;
  width: 150px;
}

.station-info {
  margin-bottom: 15px;
  font-weight: bold;
}

.pois-container {
  border: 1px solid #eee;
}

.pois-info {
  margin: 5px;
  font-weight: bold;
  font-style: italic;
}

.small-info-msg {
  font-size: 10px;
  font-style: italic;
}

.stationTooltip:hover {
  background-color: #b0acac;
}

.horizontal-line {
  margin: 5px 0;
  border-bottom: 1px solid black;
}

.alert-container {
  background-color: #ffffff66;
  width: 50px;
}

.alert-notification {
  color: white;
  text-decoration: none;
  padding: 2px 5px;
  position: relative;
  display: inline-block;
  border-radius: 2px;
  margin-top: 10px;
  cursor: pointer;
}